<template>
	<div class="headers border">
		<div class="pc">
			<img src="../assets/image/logo.png" alt="">
			<div class="nav">
				<ol>
					<li>
						<router-link to="/"  >首页</router-link>
					</li>
					<li>
						<router-link :class="[sign == 2 ? 'click':'']" to="case_index" >成功案例</router-link>
						<!-- <div class="childer">
							<router-link to="">案例一</router-link>
							<router-link to="">案例一</router-link>
							<router-link to="">案例一</router-link>
						</div> -->
					</li>
					<li>
						<router-link :class="[sign == 3 ? 'click':'']" to="product_index" >产品介绍</router-link>
					</li>
					<li>
						<router-link to="works_index"  >软件著作</router-link>
					</li>
					<li>
						<router-link to="about_index" >关于我们</router-link>
					</li>
					<li>
						<router-link to="cooperation_index" >合作洽谈</router-link>
					</li>
				</ol>
			</div>
			<div class="find">
				<input type="text" :class="[content == 1 ? 'click':'']">
				<img @click="findes" src="../assets/image/finder.png" alt="">
			</div>
		</div>
		<div class="phone">
			<img class="logo" src="../assets/image/logo.png" alt="">
			<div class="icon">
				<i style="color: #fff;font-size: 28px;" class="el-icon-menu"  @click="drawer = true" ></i>
			</div>
		</div>
		<el-drawer size="50%" :visible.sync="drawer" :direction="direction" :with-header="false">
			<div class="phone_nav">
				<el-collapse accordion>
					<!-- <div class="vlose">
						<i class="el-icon-close"></i>
					</div> -->
					<router-link class="goto" to="/">首页</router-link>
					<router-link :class="[sign == 2 ? 'click':'']" class="goto" to="case_index">成功案例</router-link>
					<router-link :class="[sign == 3 ? 'click':'']" class="goto" to="product_index">产品介绍</router-link>
					<router-link class="goto" to="works_index">软件著作</router-link>
					<router-link class="goto" to="about_index">关于我们</router-link>
					<router-link class="goto" to="cooperation_index">合作洽谈</router-link>
					<!-- <el-collapse-item title="成功案例" name="2" class="list">
						<router-link to="">案例一</router-link>
						<router-link to="">案例一</router-link>
						<router-link to="">案例一</router-link>
					</el-collapse-item> -->
				</el-collapse>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		components: {},
		props:['sign'],
		data() {
			return {
				content: 0,
				drawer: false,
				direction: 'rtl'
			}
		},
		mounted() {
			// console.log(this.sign)
		},
		methods: {
			findes() {
				this.content = 1
			},
			handleClose(done) {
				done();
			}
		}
	}
</script>

<style scoped lang="scss">
	@media screen and (min-width:900px) {
		.phone{
			display: none;
		}
		.headers {
			font-size: 0;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 9999;
			width: 100%;
			

			>div {
				width: 1200px;
				margin: 30px auto;

				>* {
					display: inline-block;
					vertical-align: middle;
				}

				>img {
					width: 10%;
				}

				.nav {
					width: 60%;
					margin: 0 60px;
					>ol {
						display: flex;
						flex-direction: row;
						justify-content: center;

						>li {
							flex: 1;
							display: inline-block;
							vertical-align: middle;
							position: relative;

							>a {
								width: 90%;
								color: #e9e9e9;
								display: inline-block;
								text-align: center;
								font-size: $font-size_16;
								padding: 5px 0;
							}
							>a.router-link-exact-active,>a.click {
								background-color: #b28850;
							}
							.childer {
								display: none;
								width: 100%;
								opacity: 0;
								position: absolute;
								bottom: -120px;
								left: 0;
								background-color: #b28850;

								>a {
									display: block;
									padding: 10px 0;
									text-align: center;
									border-bottom: 1px solid transparent;
									font-size: $font-size_14;
									color: #fff;

									&:hover {
										border-color: #fff;
									}
								}
							}

							&:hover {
								>a {
									background-color: #b28850;
								}

								.childer {
									display: block;
									opacity: 1;
								}
							}
						}
					}

				}

				>div {
					width: 20%;

					>* {
						display: inline-block;
						vertical-align: middle;
					}

					>input {
						transition: all .4s;
						color: #e9e9e9;
						width: 0;
						background-color: transparent;
						border: none;
						height: 30px;
						box-shadow: none;
						outline: none;
						margin-right: 15px;
					}

					>input.click {
						width: 80%;
						border: 1px solid #698497;
					}

					>img {
						width: 8%;
					}
				}
			}

		}
        .border{
			border-bottom: 1px solid #698497;
		}
	}

	@media screen and (max-width:900px) {
		.pc{
			display: none;
		}
		.phone{
			font-size: 0;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 9;
			width: 100%;
			border-bottom: 1px solid #698497;
			display: block;
			padding:  10px;
			box-sizing: border-box;
			.logo{
				width: 25%;
				// margin-top: 3px;
			}
			.icon{
				display: inline-block;
				width: 13%;
				float: right;
				>i{
					font-size: 35px;
					color: #698497;
				}
			}
		}
		.phone_nav{
			// padding: 0 10px;
			box-sizing: border-box;
			width: 100%;
			height: 100vh;
			// .vlose{
			// 	padding: 10px;
			// 	width: 100%;
			// 	margin-bottom: 10px;
			// 	text-align: right;
			// 	>i{
			// 		font-size: 20px;
			// 	}
			// }
			.el-collapse{
				border: none;
			}
			.goto{
				padding: 10px ;
				box-sizing: border-box;
				font-size: $font-size_14;
				border-bottom: 1px solid #EBEEF5;
				width: 100%;
				display: inline-block;
			}
			& a.router-link-exact-active,& a.click {
				background-color: #b28850;
				color: #fff;
			}
			// .list{
			// 	font-size: $font-size_14;
			// 	a{
			// 		font-size: $font-size_14;
			// 		display: block;
			// 		padding: 10px ;
			// 	}
				
			// }
		}
	}
</style>
<style>
	.el-collapse {
	    border:none;
	}
</style>
