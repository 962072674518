<template>
	<div class="index">
		<div class="banner">
			<headers></headers>
			<el-carousel :height="bannerheight+'px'" :interval="5000" arrow="always">
				<el-carousel-item>
					<img src="../../assets/image/banner.png" alt="">
				</el-carousel-item>
				<el-carousel-item>
					<img src="../../assets/image/banner02.png" alt="">
				</el-carousel-item>
				<el-carousel-item>
					<img src="../../assets/image/banner03.png" alt="">
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="introduction">
			<div class="box">
				<div class="titles">
					<div class="dv1">公司简介</div>
					<div class="dv2">Company profile</div>
				</div>
				<div class="left">
					<div class="content">
						西安志得网络技术有限公司成立于2016年07月01日，位于陕西省西安市雁塔区，是一家自主开发产品型公司，主要致力于教育行业的软件开发。多年来不断以思想引导产业，开拓了一个又一个具有志得特色的成功案例。随着信息技术的高速发展，使我们不再局限于传统的品牌策划，而是站在新的高度，迎合飞速发展的电子信息媒体，开辟出全新的电子商务解决方案。我们为企业不仅提供目前的解决方案，而且提供优秀的服务延伸。策略，创意和技术构成了我们为品牌服务的三大要素，宏观的概念模型到强大的创意实施和技术执行以及贴心的服务体系，使得整个品牌策划体系变得深入而持久。志得团队，多年不断的创新开拓，成熟而富有激情，因为我们总是不断的树立起新的起航点。
					</div>
					<router-link class="more" to="about_index">查看更多 》 </router-link>
				</div>
				<div class="right">
					<img src="../../assets/image/首页2_03.png" alt="">
				</div>
			</div>
		</div>
		<div class="advantage" ref="obtain">
			<div class="box">
				<div class="titles">
					<div class="dv1">行业优势</div>
					<div class="dv2">Industry advantages</div>
				</div>
				<transition name="el-zoom-in-center">
					<div class="list" v-show="dhshow">
						<div class="list_body">
							<img class="list_img" src="../../assets/image/首页2_07.png" alt="">
							<span class="list_tit">丰富的行业资源</span>
							<span class="list_content">与多家高等院校达成项目合作，拥有丰富的经验和客户资源。</span>
						</div>
						<div class="list_body">
							<img class="list_img" src="../../assets/image/首页2_09.png" alt="">
							<span class="list_tit">高性价比的设计方案</span>
							<span class="list_content">根据实际应用需求，设计高性价比的项目方案，合适的才是最好的。</span>
						</div>
						<div class="list_body">
							<img class="list_img" src="../../assets/image/首页2_11.png" alt="">
							<span class="list_tit">专业的研发团队</span>
							<span class="list_content">拥有经验丰富的技术团队，通过专业的眼光，为客户提供各类解决方案。</span>
						</div>
						<div class="list_body">
							<img class="list_img" src="../../assets/image/首页2_13.png" alt="">
							<span class="list_tit">完善的售后服务体系</span>
							<span class="list_content">项目完成后，安排工作人员提供7X8小时售后服务，及时处理各种问题。</span>
						</div>
					</div>
				</transition>
			</div>
		</div>
		<div class="project">
			<div class="box">
				<div class="titles">
					<div class="dv1">项目介绍</div>
					<div class="dv2">Project introduction</div>
				</div>
				<transition name="el-zoom-in-top">
					<div v-show="dhshow2" class="project_list">
						<div class="list_content">
							<div class="left">
								<img src="../../assets/image/xmjsbanner2.png" alt="">
							</div>
							<div class="right">
								<div class="tit">《掌智APP》</div>
								<div class="content">掌智app是一款在线学习软件，提供多种课程，丰富教育知识，打破时间和空间限制，方便用户随时随地在线学习和考试。</div>
								<router-link class="more" to="works_index">更多》</router-link>
							</div>
						</div>
						<div class="list_content">
							<div class="left">
								<img src="../../assets/image/xmjsbanner3.png" alt="">
							</div>
							<div class="right">
								<div class="tit">《智仔》</div>
								<div class="content">智仔是一款专业的智能课堂学习软件，用户在软件中能轻松的开发孩子的学习兴趣，帮助孩子更好的发现利用自己的潜能，让你的孩子每天都能快乐学习，茁壮成长。</div>
								<router-link class="more" to="works_index">更多》</router-link>
							</div>
						</div>
						<div class="list_content">
							<div class="left">
								<img src="../../assets/image/xmjsbanner1.png" alt="">
							</div>
							<div class="right">
								<div class="tit">《掌智教学管理系统》</div>
								<div class="content">服务于掌智APP的后台管理系统，添加各种不同的教学资源，在线考试等。</div>
								<router-link class="more" to="works_index">更多》</router-link>
							</div>
						</div>
						<div class="list_content">
							<div class="left">
								<img src="../../assets/image/xmjsbanner4.png" alt="">
							</div>
							<div class="right">
								<div class="tit">《智仔应用管理平台》</div>
								<div class="content">服务于智仔APP的后台管理系统，添加各种不同的课程资源，绘本故事等。</div>
								<router-link class="more" to="works_index">更多》</router-link>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</div>
		<div class="news">
			<div class="box">
				<div class="titles">
					<div class="dv1">行业资讯</div>
					<div class="dv2">Industry information</div>
				</div>
				<div class="natabes">
					<router-link to="" class="click">全部</router-link>
					<router-link to="">最热</router-link>
					<router-link to="">最新</router-link>
					<router-link to="">更多</router-link>
				</div>
				<div :class="[dhshow3 ? 'list trlist':'list']">
					<div class="list_content">
						<a target="_blank" href="https://mp.weixin.qq.com/s/cB10qbt68cZ7qDQ7psoFmQ">
						<div class="top">
							<img src="../../assets/image/xingyezixun1.png" alt="">
						</div>
						<div class="bot">
							<div class="time">2020-5</div>
							<div class="tit">信息化教学模式探究</div>
							<div class="content">职业教育竞争日益激烈，在实践中求创新，将创新在实践中检验，才能获得更适合的信息化教学模式。</div>
						</div>
						</a>
					</div>
					<div class="list_content">
						<a target="_blank" href="https://mp.weixin.qq.com/s/BaX-Gi9RF4o8Bxl64K25tw">
						<div class="top">
							<img src="../../assets/image/xingyezixun2.png" alt="">
						</div>
						<div class="bot">
							<div class="time">2020-5</div>
							<div class="tit">学前教育专业见习实习改革思路</div>
							<div class="content">如何使见习实习得到更好的效果？</div>
						</div>
						</a>
					</div>
					<div class="list_content">
						<a target="_blank" href="https://mp.weixin.qq.com/s/kMifrAvlznMkVTsdJgtKKw">
						<div class="top">
							<img src="../../assets/image/xingyezixun3.png" alt="">
						</div>
						<div class="bot">
							<div class="time">2020-5</div>
							<div class="tit">已做好“骂声一片”的准备了！</div>
							<div class="content">关于学前教育专业人才培养的思考</div>
						</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="cooperation">
			<div class="box">
				<div class="titles">
					<div class="dv1">合作伙伴</div>
					<div class="dv2">cooperative partner</div>
				</div>
				<transition name="el-fade-in-linear">
					<div v-show="dhshow4" class="list">
						<div class="list_content">
							<div class="left">
								<img src="../../assets/image/首页2_33.png" alt="">
							</div>
							<div class="right">
								<div class="tit">陕西金智教育咨询有限公司</div>
								<div class="content">陕西金智教育集团成立于2006年，是一家专业从事0-3岁早期教育信息咨询、培训的权威机构。</div>
								
								<a class="more" target="_blank" href="https://mp.weixin.qq.com/s/Wd-BqtN3HMbMpROrOjs-1g">更多》</a>
							</div>
						</div>
						<div class="list_content">
							<div class="left">
								<img src="../../assets/image/首页2_30.png" alt="">
							</div>
							<div class="right">
								<div class="tit">金优学能儿童学习能力教育研究中心</div>
								<div title="金优学能儿童学习能力教育研究中心成立于2012年8月。是国家教育部“科学教育”课题研究项目---儿童学习能力开发0--6岁及7--12岁儿童学习能力西安市项目基地。" class="content">金优学能儿童学习能力教育研究中心成立于2012年8月。是国家教育部“科学教育”课题研究项目---儿童学习能力开发0--6岁及7--12岁儿童学习能力西安市项目基地。</div>
								<a class="more" target="_blank" href="https://mp.weixin.qq.com/s/bX18MQgAGe6IyaDJaBFIhQ">更多》</a>
							</div>
						</div>
						<div class="list_content">
							<div class="left">
								<img src="../../assets/image/首页2_41.png" alt="">
							</div>
							<div class="right">
								<div class="tit">艾卡蒙儿童滋养中心</div>
								<div class="content">教育是一个缓释的过程，我们给予孩子的都应是科学、适合及有特点并可支撑孩子未来长期发展需要的。</div>
								<a class="more" target="_blank" href="https://mp.weixin.qq.com/s/wOcVCPn1UnKWb1POIZsv9w">更多》</a>
							</div>
						</div>
						<div class="list_content">
							<div class="left">
								<img src="../../assets/image/首页2_38.png" alt="">
							</div>
							<div class="right">
								<div class="tit">蒙洛西婴幼滋养中心</div>
								<div class="content">蒙洛西婴幼滋养中心是建立在中高档社区附近，为婴幼儿提供高品质照护养育服务的托育中心。截止目前，蒙洛西在陕西及周边地区拥已有众多加盟门店，服务了1000+家庭，越来越多的家长信任并选择蒙洛西</div>
								<a class="more" target="_blank" href="https://mp.weixin.qq.com/s/PXxk6AI8P6MG8kVyHHGv2Q">更多》</a>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</div>
		<Foot></Foot>
	</div>
</template>

<script>
	import headers from '../../components/header.vue'
	import Foot from '../../components/Foot.vue'
	export default {
		components: {
			headers,
			Foot
		},
		data() {
			return {
				windowWidth: document.documentElement.clientWidth,
				bannerheight: '',
				animation: '',
				dhshow: true,
				dhshow2: true,
				dhshow3: true,
				dhshow4: true
			}
		},
		watch: {
			//检测屏幕宽度变化
			windowWidth(val) {
				let that = this;
				if (val > 900) {
					that.bannerheight = 670
					that.animation = true
				} else {
					that.bannerheight = 200
					that.animation = false
				}
			}

		},
		mounted() {
			// this.$post('JzEducation/zhangzhi/stu/qiuzhi/servlet/selectZhaoPinHuiById',{
			// 	zhaopinhuiId:57,
			// 	jigouId:30
			// }).then((e)=>{
			// 	console.log(e)
			// })
			// this.$post('JzEducation/zhangzhi/login/selectCaiDan',{
			// 	roleId:34,
			// }).then((e)=>{
			// 	console.log(e)
			// })
			//禁止手势缩放
			document.documentElement.addEventListener('touchstart', function(event) {
				if (event.touches.length > 1) {
					event.preventDefault();
				}
			}, {
				passive: false
			});
			// 禁止双击放大
			// let lastTouchEnd = 0;
			// document.documentElement.addEventListener(‘touchend’, function(event) {
			// 	var now = Date.now();
			// 	if (now - lastTouchEnd <= 300) {
			// 		event.preventDefault();
			// 	}
			// 	lastTouchEnd = now;
			// }, {
			// 	passive: false
			// });
			//获取屏幕宽，设置banner高
			let that = this;
			let l = document.documentElement.clientWidth
			if (l > 900) {
				that.bannerheight = 670
				that.animation = true
			} else {
				that.bannerheight = 200
				that.animation = false
			}
			window.addEventListener('scroll', that.handleScrollx, true)
			// <!--把window.onresize事件挂在到mounted函数上-->
			window.onresize = () => {
				return (() => {
					window.fullWidth = document.documentElement.clientWidth;
					that.windowWidth = window.fullWidth; // 宽
				})()
			};
		},
		methods: {
			//根据屏幕宽设置动画
			handleScrollx() {
				//电脑端
				var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
				if (this.animation) {
					if (top >= 900) {
						this.dhshow = true
					} else {
						this.dhshow = false
					}
					if (top >= 1318) {
						this.dhshow2 = true
					} else {
						this.dhshow2 = false
					}
					if (top >= 2000) {
						this.dhshow3 = true
					} else {
						this.dhshow3 = false
					}
					if (top >= 2400) {
						this.dhshow4 = true
					} else {
						this.dhshow4 = false
					}
				}
				//手机端
				if (!this.animation) {
					if (top >= 1000) {
						this.dhshow = true
					} else {
						this.dhshow = false
					}
					if (top >= 1900) {
						this.dhshow2 = true
					} else {
						this.dhshow2 = false
					}
					if (top >= 2380) {
						this.dhshow3 = true
					} else {
						this.dhshow3 = false
					}
					if (top >= 3700) {
						this.dhshow4 = true
					} else {
						this.dhshow4 = false
					}
				}

				// console.log('滚动高度', top)
				// console.log('距离顶部高度', this.$refs.obtain.getBoundingClientRect().top)
			}
		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/less/index.scss";
</style>
<style>
	.el-carousel__item {}

	.el-carousel__item img {
		width: 100%;
	}
	@media screen and (max-width:900px){
		.el-carousel__item img {
			width: auto;
			height: 100%;
		}
	}
</style>
