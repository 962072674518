import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index/index.vue'
import {
	verificationtoken
} from "@/http/http.js"
Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'Index',
		component: Index
	},
	{
		path: '/case_index',
		name: 'case_index',
		component: () => import('../views/case/index.vue')
	},
	{
		path: '/case_details',
		name: 'case_details',
		component: () => import('../views/case/details.vue')
	},
	{
		path: '/product_index',
		name: 'product_index',
		component: () => import('../views/product/index.vue')
	},
	{
		path: '/product_details',
		name: 'product_details',
		component: () => import('../views/product/details.vue')
	},
	{
		path: '/works_index',
		name: 'works_index',
		component: () => import('../views/works/index.vue')
	},
	{
		path: '/works_details',
		name: 'works_details',
		component: () => import('../views/works/details.vue')
	},
	{
		path: '/about_index',
		name: 'about_index',
		component: () => import('../views/about/index.vue')
	},
	{
		path: '/cooperation_index',
		name: 'cooperation_index',
		component: () => import('../views/cooperation/index.vue')
	}
]

const router = new VueRouter({
	routes
})
router.beforeEach((to, from, next) => {
	document.body.scrollTop = 0
	document.documentElement.scrollTop = 0
	window.pageYOffset = 0
	next()
})

export default router
