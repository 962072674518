export default {
	serverSrc: 'http://121.36.61.2:8082/', //测试服务器地址
	// serverSrc: 'http://192.168.0.222:8080/', //测试 冯钊
	// serverSrc: 'https://www.zhangzhi.xin/', //测试服务器地址
	// serverSrc: 'http://47.104.250.67:8088/', //测试服务器地址
	// serverSrc: 'http://192.168.0.84:8088/', //测试 陈宁康
	// serverSrc: 'https://www.chenningkang.top/', //测试服务器地址
	// serverSrc: 'http://192.168.0.188:8088/', //
	// serverSrc: 'http://192.168.0.188:8081/', //党田
	//serverSrc: 'http://192.168.6.129:8081/',
	// serverSrc: 'http://192.168.0.36:8080/', //c陈宁康
	// serverSrc: 'http://192.168.0.190:8080/', //陈宁康
	// serverSrc: 'https://www.chenningkang.top/', //陈宁康
	// serverSrc: 'https://www.zhangzhi.xin/',
	// serverSrc: 'https://www.chenningkang.top/',
	// serverSrc: 'http://114.116.236.214:8082',
	//     serverSrc: 'https://www.zhidenet.xin/',
	/*
   时间戳转时间
     */
	timeFn(value) {
		return new Date(parseInt(value)).toLocaleString()
	},
	getLocalTime(nS) {
		return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
	},
	getLocalTimehm(hs) {
		return new Date(parseInt(hs) * 1).toLocaleString().replace(/:\d{1,2}$/, ' ');
	},
	timeFn2(value) {
		// return new Date(parseInt(value)).toLocaleString().replace(/年|月/g, "-").replace(/日/g, " ")
		return new Date(parseInt(value)).toLocaleString().substr(13)
	},
	timeFn3(value) {
		if (value != '' && value != null) {
			var str = value.substr(0, 4)
			return str + '.' + value.substr(4, 2) + '.' + value.substr(6, 2)
		} else {
			return ''
		}
	},

	format(shijianchuo,type) {
		//shijianchuo是整数，否则要parseInt转换
		function add0(m) {
			return m < 10 ? '0' + m : m
		}
		var time = new Date(shijianchuo);
		var y = time.getFullYear();
		var m = time.getMonth() + 1;
		var d = time.getDate();
		var h = time.getHours();
		var mm = time.getMinutes();
		var s = time.getSeconds();
		if(type == 1){
			return y + '-' + add0(m) + '-' + add0(d);
		}else{
			return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
		}
		
	},
	/*
	string 字符串;
	str 指定字符;
	split(),用于把一个字符串分割成字符串数组;
	split(str)[0],读取数组中索引为0的值（第一个值）,所有数组索引默认从0开始;
	 */
	// 截取指定字符串之前的字符
	getBeforStr(string, str) {
		var str_before = string.split(str)[0]
		return str_before
	},
	// 截取指定字符串之后的字符
	getAfterStr(string, str) {
		var str_after = string.split(str)[1]
		return str_after
	},
	// 数组去重
	unique(arr) {
		for (var i = 0; i < arr.length - 1; i++) {
			for (var j = i + 1; j < arr.length; j++) {
				if (arr[i] == arr[j]) {
					arr.splice(j, 1)
					j--
				}
			}
		}
		return arr
	},
	// 使用对象的方法对数组去重
	unique2(arr) {
		var json = {}
		var myArr = []
		for (var i in arr) {
			if (!json[arr[i]]) {
				json[arr[i]] = true
				myArr.push(arr[i])
			}
		}
		return myArr;
	},
	// 取出两个数组的相同元素
	getArrEqual(arr1, arr2) {
		let newArr = [];
		for (let i = 0; i < arr2.length; i++) {
			for (let j = 0; j < arr1.length; j++) {
				if (arr1[j] === arr2[i]) {
					newArr.push(arr1[j]);
				}
			}
		}
		return newArr;
	},
	// 截取指定字数的字符串
	assignNum(str, len) {
		let h = parseFloat(str.length)
		if (h&&h > len) {
			return str.substr(0, len) + '...'
		} else {
			return str
		}
	},

	// 1 2 3转为a b c
	convert(num) {
		var saveLet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T",
			"U", "V", "W", "X", "Y", "Z"
		];
		var singleLet = "";
		var val = 0;

		while (num > 0) {
			val = (num - 1) % 26
			singleLet = saveLet[val] + singleLet;
			num = Math.floor((num - 1) / 26);
		}
		return singleLet;
	},
	// 将数组的每一项转为int类型
	intArr(arr) {
		var myArr = []
		for (var i = 0; i < arr.length; i++) {
			myArr.push(parseInt(arr[i]))
		}
		return myArr
	},
	// 格式化时间
	filtrationTimeCommon(myDate,type) {
		function add0(m) {
			return m < 10 ? '0' + m : m
		}
		var Y = myDate.getFullYear() ,
			M = (myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1) ,
			D = myDate.getDate() ,
			h = myDate.getHours() ,
			m = myDate.getMinutes(),
			s = myDate.getSeconds(),
			timeStr = ''
			if (type) {
				console.log(add0(m))
				timeStr = add0(h) +':'+ add0(m)
			} else {
				timeStr = add0(Y) +"-"+ add0(M) +"-"+ add0(D) +" "+ add0(h) +":"+ add0(m) +":"+ add0(s)
			}
		return timeStr
	},
	/*过滤掉字符串中的特殊字符*/
	stripscript(s) {
		//var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
		var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“']")
		var rs = "";
		for (var i = 0; i < s.length; i++) {
			rs = rs + s.substr(i, 1).replace(pattern, '');
		}
		//console.log(rs)
		return rs;
	},
	leftTrim(s) {
		//去掉字符串左边所有的换行符
		if (s != '' && s != null && s != undefined) {
			s = s.replace(/^\r\n*/g, "");
			s = s.replace(/^\n*/g, "");

			//去掉字符串左边所有的空格（中文空格、英文空格都会被替换）
			s = s.replace(/^\s*/g, "");
			return s;
		}
	},
	/*删除a数组所有与b数组相同的
   var a=[1,2,3,4,5,6,7,8]
  var b=[4,5,6,7,8,9,10]
  debugger
    arrChange(a,b)
  console.log(a) //[1, 2, 3]
  */
	arrChange(a, b) {
		var arr = a.concat()
		for (var i = 0; i < b.length; i++) {
			for (var j = 0; j < arr.length; j++) {
				if (arr[j] == b[i]) { //如果是id相同的，那么a[ j ].id == b[ i ].id
					arr.splice(j, 1);
					j = j - 1;
				}
			}
		}
		return arr;
	},
	/* 倒计时 */
	daojishi(time, timer) {
		var nowtime = new Date(), //获取当前时间
			endtime = new Date(time); //定义结束时间
		var lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
			leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
			lefth = Math.floor(lefttime / (1000 * 60 * 60) % 24), //计算小时数
			leftm = Math.floor(lefttime / (1000 * 60) % 60), //计算分钟数
			lefts = Math.floor(lefttime / 1000 % 60); //计算秒数
		if (lefttime > 0) {
			return leftd + "天" + lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
		} else {
			clearInterval(timer);
		}
		// return leftd + "天"+ ":" + lefth + "时" + ":" + leftm + "分" + ":" + lefts + "秒";  //返回倒计时的字符串
	},
	stringTosplicing(data, ty) {
		let str1 = ''
		for (let i = 0; i < data.length; i++) {
			if (i < data.length - 1) {
				if (ty == 'id') {
					if (data[i].id) {
						str1 += data[i].id + ",";
					}
				} else if (ty == 'content') {
					if (data[i].content) {
						str1 += data[i].content + ",";
					}
				} else {
					str1 += data[i] + ",";
				}
			} else {
				if (ty == 'id') {
					str1 += data[i].id;
				} else if (ty == 'content') {
					str1 += data[i].content;
				} else {
					str1 += data[i];
				}
			}
		}
		return str1
	}
}
