<template>
	<div class="foot">
		<div class="body">
			<img @click="optop" class="top" src="../assets/image/gj7t.png" alt="">
			<div class="left">
				<ol>
					<li>服务</li>
					<li> <router-link to="">>首页</router-link> </li>
					<li>
						<router-link to="">>产品目录</router-link>
					</li>
					<li>
						<router-link to="">>软件著作</router-link>
					</li>
					<li>
						<router-link to="">>成功案例</router-link>
					</li>
				</ol>
				<ol>
					<li>关于我们</li>
					<li>
						<router-link to="">>行业资讯</router-link>
					</li>
					<li>
						<router-link to="">>公司介绍</router-link>
					</li>
					<li>
						<router-link to="">>合作洽谈</router-link>
					</li>
					<li>
						<router-link to="">>联系我们</router-link>
					</li>
				</ol>
				<ol>
					<li>合作伙伴</li>
					<li>
						<router-link to="">>金智教育</router-link>
					</li>
					<li>
						<router-link to="">>蒙诺西</router-link>
					</li>
					<li>
						<router-link to="">>金优学能</router-link>
					</li>
				</ol>
			</div>
			<div class="right">
				<!-- <div class="address">公司地址</div>
				<div class="city">陕西省西安市</br>xxx区xxx路xxx</div>
				<div class="city city_phone">陕西省西安市xxx区xxx路xxx</div>
				<div class="phone">电话：xxxxxx</div>
				<div class="email">邮箱：xxxxxx</div> -->
			</div>
			<div class="work">版权所有：西安志得网络技术有限公司<a target="_blank" href="https://beian.miit.gov.cn/">备案号：陕ICP备17009726号-4</a></div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'foot',
		components: {

		},
		data() {
			return {

			}
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll, true);
		},
		methods: {
			optop() {
				let top = document.documentElement.scrollTop || document.body.scrollTop;
				// 实现滚动效果 
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			}
		}
	}
</script>
<style scoped lang="scss">
	.foot {
		background-color: #161616;

		.body {
			padding: 60px 0 20px 0;
			position: relative;
			width: 1200px;
			margin: 0 auto;

			.top {
				position: absolute;
				right: -20px;
				top: -20px;
				z-index: 999;
				cursor: pointer;
			}

			.left,
			.right {
				display: inline-block;
				vertical-align: top;
			}

			.left {
				font-size: 0;
				width: 70%;
				border-right: 1px solid #434343;
				padding-right: 40px;
				box-sizing: border-box;
				margin-right: 10%;

				>ol {
					display: inline-block;
					vertical-align: top;
					width: 33.3%;
					padding: 0 30px;
					box-sizing: border-box;

					>li {
						
						>a {
							display: inline-block;
							width: 100%;
							font-size: $font-size_16;
							color: #a6a6a6;
						}
							margin-bottom: 15px;
					}

					>li:first-child {
						color: #fff;
						font-size: $font-size_18;
						margin-bottom: 25px;
					}

					>li:last-child {
						margin-bottom: 0;
					}
				}
			}

			.right {
				.address {
					font-size: $font-size_20;
					color: #fff;
					margin-bottom: 25px;
				}

				.city {
					font-size: $font-size_16;
					color: #a6a6a6;
					margin-bottom: 30px;
				}
				.city_phone{
					display: none;
				}
				.phone,
				.email {
					margin-bottom: 5px;
					padding-bottom: 5px;
					font-size: $font-size_16;
					color: #a6a6a6;
					border-bottom: 1px solid #a6a6a6;
				}
			}
			.work{
				text-align: center;
				margin: 20px 0 0 0;
				padding: 10px 0 0 0;
				 border-top: 1px solid #a6a6a6;
				color: #a6a6a6;
				font-size: $font-size_16;
				>a{
					margin-left: 40px;
				}
			}
		
		}

	}
	@media screen and (max-width:900px){
		.foot{
			.body{
				padding: 40px 0 20px 0;
				width: 100%;
				.top{
					right: 15px;
					width: 10%;
					top: -15px;
				}
				.left{
					width: 100%;
					margin: 0;
					padding: 0;
					
					>ol {
						padding: 0 20px;
						>li {
							
							>a {
								
								font-size: $font-size_14;
							}
						}
					
						>li:first-child {
							font-size: $font-size_16;
						}
					
					}
				}
				.right{
					width: auto;
					display: inline-block;
					// margin: 20px 0 0 0;
					padding: 20px;
					.address{
						font-size: $font-size_18;
						margin-bottom: 10px;
					}
					.city {
						font-size: $font-size_14;
						display: none;
						margin-bottom: 10px;
					}
					.city_phone{
						display: block;
					}
					.phone,
					.email {
						font-size: $font-size_14;
					}
				}
			    .work{
			    	font-size: $font-size_14;
			    	>span{
						display: block;
						width: 100%;
						text-align: center;
						margin: 10px 0 0 0;
			    	}
			    }
			}
		}
	}
</style>
